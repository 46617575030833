import { useState } from "react";
import { Form } from "../../../../components/form/Form";
import { StoryStepProps } from "../../../../components/story/Story";
import { T } from "../../../../components/translation/T";
import { TextInput } from "../../../../components/form/TextInput";
import { RequiredValidator } from "../../../../components/form/validators/RequiredValidator";
import { useAccess } from "../../../../hooks/useAccess";
import { Access } from "../../../../data/proxy";
import { StoryContinueButton } from "../../../../components/story/StoryContinueButton";
import { Trans, useTranslation } from "react-i18next";
import { useLinkId } from "../../../../hooks/useLinkId";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { dataBank } from "../../../../data/dataBank";
import { useRecoilValue } from "recoil";
import { contractState } from "../../../../state/contractState";
import { Dynamic } from "../../../../components/animate/Dynamic";
import { GenericError } from "../../../../components/Errors/GenericError";
import { MinLengthValidator } from "../../../../components/form/validators/MinLengthValidator";
import { MaxLengthValidator } from "../../../../components/form/validators/MaxLengthValidator";

const MIN_LENGTH_ACCOUNT = 7;
const MAX_LENGTH_ACCOUNT = 7;
const MIN_LENGTH_CLEARING = 4;
const MAX_LENGTH_CLEARING = 4;

// function parseSavedBankAccount(account?: string): string[] {
//   if (!account) {
//     return ["", ""];
//   }

//   return [
//     account.substring(0, MAX_LENGTH_CLEARING),
//     account.substring(
//       MAX_LENGTH_CLEARING,
//       MAX_LENGTH_CLEARING + MAX_LENGTH_ACCOUNT
//     ),
//   ];
// }

export const Kar: React.FunctionComponent<StoryStepProps> = ({
  next,
  pageRef,
}) => {
  const { t } = useTranslation();
  const linkId = useLinkId();
  const access = useAccess();
  const queryClient = useQueryClient();
  // const { data: bank } = useSuspendedQuery(
  //   dataBank(access).fetchConfirmedStatus(linkId)
  // );

  // const values = useMemo(() => parseSavedBankAccount(bank.bankAccount), [bank]);
  const [clearing, setClearing] = useState<string>();
  const [bankAccount, setBankAccount] = useState<string>();

  const contract = useRecoilValue(contractState);

  const {
    mutate: onSave,
    isLoading,
    isError,
    reset,
  } = useMutation(
    () => {
      if (typeof clearing !== "string") {
        return Promise.reject();
      }

      if (typeof bankAccount !== "string") {
        return Promise.reject();
      }

      return dataBank(access).saveKarAccount(
        linkId,
        `${clearing}${bankAccount}`.trim(),
        contract.contractData.legalEntityType
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          dataBank(access).getConfirmedStatusKey(linkId)
        );
        next();
      },
    }
  );

  return (
    <>
      <h2>
        <T>Payout account</T>
      </h2>
      <p>
        <T>
          We need you to add a payout account that will be used for your
          transactions.
        </T>{" "}
        <Trans t={t}>
          We will validate this account through{" "}
          <b>Konto- og adresseringsregisteret</b> (KAR).
        </Trans>
      </p>

      <Form
        onSubmit={(_, form) => {
          if (!form.isValid) {
            return;
          }

          onSave();
        }}
      >
        <p>
          <b>Exempel:</b> 8601 1117947
        </p>

        <div className="m-top-40">
          <div className="tablet-columns">
            <div>
              <TextInput
                name="clearing"
                label="Clearing number"
                value={clearing}
                disabled={isLoading}
                placeholder="E.g. 8601"
                hint={
                  <>
                    <b>4</b> characters
                  </>
                }
                validators={[
                  new RequiredValidator("Required"),
                  new MinLengthValidator(
                    MIN_LENGTH_CLEARING,
                    (
                      <T
                        id={"At least {{min}} digits are required"}
                        options={{
                          min: MIN_LENGTH_CLEARING,
                        }}
                      />
                    )
                  ),
                  new MaxLengthValidator(
                    MAX_LENGTH_CLEARING,
                    (
                      <T
                        id={"Not more than {{max}} digits are allowed"}
                        options={{
                          max: MAX_LENGTH_CLEARING,
                        }}
                      />
                    )
                  ),
                ]}
                onChange={(value) => {
                  reset();
                  setClearing(value);
                }}
              />
            </div>
            <div>
              <TextInput
                name="bankAccount"
                label="Account number"
                value={bankAccount}
                disabled={isLoading}
                placeholder="E.g. 1117947"
                hint={
                  <>
                    <b>7</b> characters
                  </>
                }
                validators={[
                  new RequiredValidator("Required"),
                  new MinLengthValidator(
                    MIN_LENGTH_ACCOUNT,
                    (
                      <T
                        id={"At least {{min}} digits are required"}
                        options={{
                          min: MIN_LENGTH_ACCOUNT,
                        }}
                      />
                    )
                  ),
                  new MaxLengthValidator(
                    MAX_LENGTH_ACCOUNT,
                    (
                      <T
                        id={"Not more than {{max}} digits are allowed"}
                        options={{
                          max: MAX_LENGTH_ACCOUNT,
                        }}
                      />
                    )
                  ),
                ]}
                onChange={(value) => {
                  reset();
                  setBankAccount(value);
                }}
              />
            </div>
          </div>
        </div>

        <Dynamic name={isError ? "error" : ""}>
          {isError ? <GenericError /> : null}
        </Dynamic>

        <StoryContinueButton
          type="submit"
          className="m-top-30"
          disabled={access === Access.VIEW}
          isLoading={isLoading}
        >
          <T>Save account</T>
        </StoryContinueButton>
      </Form>
    </>
  );
};
